












































































































































































































































































































































































































































import Vue, { PropType } from "vue";
import { Product } from "@/store/products/types";
import { namespace } from "vuex-class";
import { Utils } from "@/utils/";
import { ProductCriteria } from "../../api/products";
import { Paging } from "../../store/utils";
import { debounce } from "lodash";
import { Lookups } from "../../api/lookups";
import * as qr from "qrcode-generator";

export default Vue.extend({
  name: "Products",

  computed: {
    // both get and set
    _pagination: {
      get: function () {
        return this.$store.state.products.pagination;
      },
      set: function (value) {
        this.$store.dispatch("products/paginate", value);
      },
    },
    products: function () {
      return this.$store.state.products.products;
    },
    totalItems: function () {
      return this.$store.state.products.totalItems;
    },
    loading: function () {
      return this.$store.state.products.loading;
    },
  },

  watch: {
    filteredHeaders: function (items: [], oldItems: []) {
      if (items === oldItems) return;
      items.sort((a: any, b: any) => {
        return this.headers.indexOf(a) - this.headers.indexOf(b);
      });
    },

    /* If page changed get new data */
    _pagination: {
      handler(val: Paging) {
        if (val.sortBy) this.productsCriteria.order = ["position"];
        this.$store.dispatch("products/getProducts", {
          body: this.productsCriteria,
          showDisabled: this.showDisabled,
        });
      },
      deep: true,
    },

    activeShopToken: {
      async handler(val: string) {
        this.productsCriteria.tags = [];
        const response = await Lookups.getCategories();
        this.categoriesLookup = response.data.results;
      },
      deep: true,
      immediate: true,
    },
  },

  data: function () {
    return {
      imgDialog: false,
      deleteDialog: false,
      enlargedImage: "",

      showDisabled: false,
      selectedProduct: {} as any,
      productSearch: "",
      categorySelect: [],
      categoriesLookup: [],

      productsCriteria: {} as ProductCriteria,
      fetchLookups: Lookups.fetchLookups,

      dialogProduct: false,
      qrImgTag: "",

      debounced: () => {},

      headers: [
        { text: "Images", value: "images", sortable: false },
        { text: "SKU", value: "sku", sortable: false },
        { text: "Name", value: "name", sortable: false },
        {
          text: "Price",
          value: "price",
          class: "currency-width",
          sortable: false,
        },
        { text: "Categories", value: "tags", sortable: false },
        { text: "Stock Level", value: "stockLevel", sortable: false },
        { text: "In Stock", value: "available", sortable: false },
        { text: "On App", value: "availableOnline", sortable: false },
        { text: "Active", value: "enabled", sortable: false },
        {
          text: "Position",
          value: "position",
          sortable: false,
          align: "center",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],

      filteredHeaders: [
        { text: "Images", value: "images", sortable: false },
        { text: "SKU", value: "sku", sortable: false },
        { text: "Name", value: "name", sortable: false },
        {
          text: "Price",
          value: "price",
          class: "currency-width",
          sortable: false,
        },
        { text: "Categories", value: "tags", sortable: false },
        { text: "Stock Level", value: "stockLevel", sortable: false },
        { text: "In Stock", value: "available", sortable: false },
        { text: "On App", value: "availableOnline", sortable: false },
        { text: "Active", value: "enabled", sortable: false },
        {
          text: "Position",
          value: "position",
          sortable: false,
          align: "center",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],

      hasLabel: null,
      hasOptions: "",
      loadPreview: false,

      snack: false,
      snackColor: "",
      snackText: "",
    };
  },

  async mounted() {
    // Initial debounce func for shop search
    this.debounced = debounce(() => {
      this.onSearch();
    }, 400);

    const response = await Lookups.getCategories();
    this.categoriesLookup = response.data.results;

    this.$store.dispatch("products/getProducts", {
      body: this.productsCriteria,
      showDisabled: this.showDisabled,
    });
  },

  methods: {
    onFilteredChanged(items: [], oldItems: []) {
      if (items === oldItems) return;
      items.sort((a: any, b: any) => {
        return this.headers.indexOf(a) - this.headers.indexOf(b);
      });
    },
    onNextPage(val: Paging) {
      if (val.sortBy) this.productsCriteria.order = ["position"];
      this.$store.dispatch("products/getProducts", {
        body: this.productsCriteria,
        showDisabled: this.showDisabled,
      });
    },
    async onShopChange(val: string) {
      this.productsCriteria.tags = [];
      const response = await Lookups.getCategories();
      this.categoriesLookup = response.data.results;
    },
    isHeaderToggled(text: string) {
      return this.headers.some((obj: any) => obj.text !== text);
    },
    onSearch() {
      this.productsCriteria.name = this.productSearch;
      this.productsCriteria.tags = this.categorySelect;
      this.$store.dispatch("products/getProducts", {
        body: this.productsCriteria,
        showDisabled: this.showDisabled,
      });
    },
    enlargeImage(imageSrc: string) {
      this.imgDialog = true;
      this.enlargedImage = imageSrc;
    },
    onShowDisabled(value: boolean) {
      this.$store.dispatch("products/getProducts", {
        body: this.productsCriteria,
        showDisabled: this.showDisabled,
      });
    },
    editProduct(product: Product) {
      this.$router.push({
        name: "product-edit",
        params: { productId: product.id.toString() },
      });
    },
    previewProduct(product: any) {
      this.loadPreview = true;
      this.selectedProduct = product;
      this.checkTags(product.tags);
      this.dialogProduct = true;
      this.loadPreview = false;
    },
    selectProductToDelete(product: Product) {
      this.deleteDialog = true;
      this.selectedProduct = product;
    },
    async disableSelectedProduct(product: Product) {
      this.deleteDialog = false;
      const success = await this.$store.dispatch(
        "products/deleteProduct",
        +product.id
      );
      if (success) {
        this.$store.dispatch("products/getProducts", {
          body: this.productsCriteria,
          showDisabled: this.showDisabled,
        });
      }
    },
    cancelDelete() {
      this.deleteDialog = false;
      this.selectedProduct = {};
    },
    onImageUpload(productId: any) {
      (<any>this.$refs[`image-${productId}`]).click();
    },
    async imageSelected(e: any) {
      if (e.target.files[0]) {
        const formData = new FormData();
        formData.append("image", e.target.files[0]);
        const success = await this.$store.dispatch(
          "products/addProductImage",
          { productId: e.target.dataset.productId, formData }
        );
        if (success)
          this.$store.dispatch("products/getProducts", {
            body: this.productsCriteria,
            showDisabled: this.showDisabled,
          });
      }
    },
    async onImageDelete(productId: number, imageUrl: string) {
      const imageName = imageUrl.substr(imageUrl.lastIndexOf("/") + 1);

      const success = await this.$store.dispatch(
        "products/deleteProductImage",
        { productId, imageName }
      );
      if (success)
        this.$store.dispatch("products/getProducts", {
          body: this.productsCriteria,
          showDisabled: this.showDisabled,
        });
    },
    async onUpdate() {
      const form: any = this.$refs["form"];
      let allOK = await form.$validator.validateAll();
      if (allOK) {
        const success = await this.$store.dispatch("products/productUpdate", {
          body: form.model,
          inline: false,
        });
        if (success) {
          this.$store.dispatch("products/getProducts", {
            body: this.productsCriteria,
            showDisabled: this.showDisabled,
          });
        }
      }
    },
    async onQuickUpdate(item: any, action: string) {
      item[action] = !item[action];
      const success = await this.$store.dispatch("products/productUpdate", {
        body: item,
        inline: false,
      });
      if (success) {
        this.$store.dispatch("products/getProducts", {
          body: this.productsCriteria,
          showDisabled: this.showDisabled,
        });
      }
    },
    formatCurrency(text: string) {
      return Utils.formatText(text, Utils.TextType.CURRENCY);
    },
    getCheapestOption() {
      let cheapestOption = 0;
      if (this.selectedProduct) {
        if (
          this.selectedProduct.optionSets &&
          this.selectedProduct.optionSets.length
        ) {
          this.selectedProduct.optionSets.forEach((optionSet: any) => {
            if (optionSet.options && optionSet.options.length) {
              optionSet.options.forEach((option: any) => {
                if (
                  (cheapestOption == 0 || option.price < cheapestOption) &&
                  option.price != null
                ) {
                  cheapestOption = option.price;
                  this.hasOptions = "From";
                }
              });
            } else {
              this.hasOptions = "";
            }
          });
        } else {
          this.hasOptions = "";
        }

        const productPrice = this.selectedProduct.price + cheapestOption;
        return this.formatCurrency(`${productPrice}`);
      }
    },
    checkTags(tags: any) {
      this.hasLabel = null;
      if (tags && tags.length) {
        tags.forEach((tag: any) => {
          // only select the 1st one
          if (tag.tagType.name == "label" && !this.hasLabel) {
            this.hasLabel = tag;
          }
        });
      }
    },
    async generateQR(sku: string) {
      let qrCode = qr.default(0, "L");

      const qrData = `${process.env.VUE_APP_QR_PRODUCT_ADD}?sku=${sku}&orgId=${this.$store.state.shops.activeShop.id}`;

      qrCode.addData(qrData);
      qrCode.make();

      this.qrImgTag = qrCode.createSvgTag(4, 5);

      const documentData = document.querySelector(".printqr");

      await this.$nextTick();

      const svgAsXML = documentData
        ? new XMLSerializer().serializeToString(documentData)
        : "n/a";
      const svgData = "data:image/svg+xml," + encodeURIComponent(svgAsXML);

      const dl = document.createElement("a");
      document.body.appendChild(dl); // This line makes it work in Firefox.
      dl.setAttribute("href", svgData);
      dl.setAttribute("download", "product-sku-" + sku + ".svg");
      dl.click();
    },
    async save(item: any, inline: boolean = true) {
      const success = await this.$store.dispatch("products/productUpdate", {
        body: item,
        inline: inline,
      });
      if (success) {
        this.$store.dispatch("products/getProducts", {
          body: this.productsCriteria,
          showDisabled: this.showDisabled,
        });
      }
    },
    async saveStockLevel(item: any) {
      const success = await this.$store.dispatch(
        "products/productStockLevelUpdate",
        { productId: item.id, quantity: item.stockLevel }
      );
      if (success) {
        this.$store.dispatch("products/getProducts", {
          body: this.productsCriteria,
          showDisabled: this.showDisabled,
        });
      }
    },
    cancel() {
      this.snack = true;
      this.snackColor = "warning";
      this.snackText = "Update canceled";
    },
  },
});
